export default {
	data: () => ({
		langRu: {
			linkHome: 'Домой',
			title:'Специалисты',
			btn: 'Записаться',
			btn2: 'Магазин',
			selectTitle: 'Выберите дату,чтобы записаться',
			price: 'Консультация от',
			reload: 'Загрузи больше',
			result: 'результатов',
			search: 'Найди',
			address: 'Адрес',
			certificateTitle: {
				text: 'Сертификаты и ',
				color: 'дипломы'
			}
		}
	})
}