export default {
	data: () => ({
		langRo: {
			linkHome: 'Acasa',
			title:'Specialiști',
			btn: 'Programează-te',
      btn2: 'Magazin',
			selectTitle: 'Alegeți o dată pentru a vă înscrie',
			price: 'Consultație',
			reload: 'Mai încarcă',
			result: 'rezultate',
			search: 'Caută',
			address: 'Locație',
			certificateTitle: {
				text: 'Certificate și',
				color: 'diplome'
			}
		}
	})
}