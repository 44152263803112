export default {
	data: () => ({
		langEng: {
			linkHome: 'Acasa',
			title:'Specialists',
			btn: 'make an appointment',
      btn2: 'Shop',
			selectTitle: 'Choose a date to sign up',
			price: 'Consultation from',
			reload: 'Load more',
			result: 'results',
			search: 'Search',
			address: 'Address',
			certificateTitle: {
				text: 'Certificates and ',
				color: 'diplomas'
			}
		}
	})
}