<template>
<div class="specialist_page">
  <div class="page__breadcrumbs">
    <h1>{{ specialist.name }}</h1>
  </div>
  <div class="specialist__cnt">
    <div class="img__cont">
      <img :src="specialist.photo" alt="">
    </div>
    <div class="about__col">
      <div class="top">
        <div class="details">
          <div class="name">{{ specialist.name }}</div>
          <div class="function">{{ specialist.position }}</div>
          <div class="address">{{specialist.address}}</div>
        </div>
        <div class="price" v-if="specialist.price">
          <span>{{$t('specialists.price')}}</span>
          <div class="num">{{ specialist.price }} {{ specialist.money_type }}</div>
        </div>
      </div>
      <p class="page__breadcrumbs_about" v-html="specialist.about">
        
      </p>
      <div class="appointment">
        <div class="row">
          <a @click.prevent="modal = true" href="#" class="appoint__btn">
            {{$t('specialists.btn')}}
          </a>
          <a v-if="specialist.shop_is_active" @click.prevent="$router.push({path: `/shop/${specialist.id}/`, 'lang': lang})"  class="appoint__btn right_btn">
            {{$t('specialists.btn2')}}
          </a>
        </div>
      </div>
    </div>
  </div>
  <div class="diplomas__cnt" v-if="certificates.length">
    <h2>{{$t('specialists.certificateTitle.text')}} <b>{{$t('specialists.certificateTitle.color')}}</b></h2>
    <div class="diplomas__row">
      <div class="card" 
        v-for="(card, ind) in certificates.slice(1)" 
        :key="card.id"
        v-show="card.show"
        @click="openLightBox(ind)"
      >
        <img
         v-if="!checkPdfLink(card.image)"
         :src="card.image"
          alt=""
        >
        <!-- <img v-else src="/img/diploma3.jpg" alt=""> -->
       <div class="pdf_object" v-else>
          <iframe 
            :src="card.image + '#toolbar=0&navpanes=0'"
            width="100%" 
            height="311px"
          />
        </div>
       
      </div>
    </div>
  </div>
  <PersonalSlider v-if="wrappWorksItems.length" :reviews="wrappWorksItems" :title="translateText.sliderWork[lang]" titleBold=" "/>
  <div class="space-240"></div>
   <AppointmentSpecialist :userId="specialist.id" :class="{'active': modal}" @close="modal = false" v-scroll-lock="modal"/>
  <CoolLightBox 
    :items="lightBox" 
    :index="indLightBox"
    :slideshow="false"
    :useZoomBar="true"
    :closeOnClickOutsideMobile="true"
    @close="indLightBox = null"
  >
  </CoolLightBox>
</div>
</template>
<script>
import LangRu from '@/lang/specialists/ru' 
import LangRo from '@/lang/specialists/ro' 
import LangEng from '@/lang/specialists/eng' 
import LangEs from '@/lang/specialists/es' 

import PersonalSlider from '@/components/PersonalSlider'
import AppointmentSpecialist from '@/components/AppointmentSpecialist'
import CoolLightBox from 'vue-cool-lightbox'

import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'

import { mapGetters } from "vuex"

const domain = process.env.VUE_APP_BASE_API;

export default {
  mixins: [LangRu, LangRo, LangEng, LangEs],
  components: {
    PersonalSlider,
    AppointmentSpecialist,
    CoolLightBox
  },
  data:()=>({
    specialist: {
      name: '',
      certificates: [],
      works: []
    },
    e404: false,
    modal: false,
    lightBox: [],
    indLightBox: null,
    translateText: {
      sliderWork: {
        ro: 'Trаtamente',
        ru: 'Процедуры',
        en: 'Treatments',
        es: 'Tratamientos'
      }
    }
  }),
  metaInfo() {
    return this.lang == 'ru' ? {
      title: 'Специалисты академии красоты Lica Doctor',
      meta: [
        {
          vmid: 'title',
          name: 'title',
          content: 'Специалисты академии красоты Lica Doctor'
        },
        {
          vmid: 'description',
          name: 'description',
          content: 'Сертифицированные специалисты в области косметологии и эстетической медицины предоставят вам лучшие материалы, которые помогут вам достичь вашей цели'
        }
      ]
    } :
    this.lang == 'ro' ? {
      title: 'Specialiști Academiei de frumusețe Lica Doctor',
      meta: [
        {
          vmid: 'title',
          name: 'title',
          content: 'Specialiști Academiei de frumusețe Lica Doctor'
        },
        {
          vmid: 'description',
          name: 'description',
          content: 'Specialiști certificați în sfera vă vor oferi cele mai bune materiale pentru a vă ajuta să vă atingeți scopul.'
        }
      ]
    } :
    this.lang == 'es' ? {
      title: 'Especialistas de la Academia de Belleza Lica Doctor',
      meta: [
        {
          vmid: 'title',
          name: 'title',
          content: 'Especialistas de la Academia de Belleza Lica Doctor'
        },
        {
          vmid: 'description',
          name: 'description',
          content: 'Profesionales certificados en la materia le brindarán los mejores materiales para ayudarle a lograr su objetivo.'
        }
      ]
    } :
    {
      title: 'Lica Doctor Beauty Academy Specialists',
      meta: [
        {
          vmid: 'title',
          name: 'title',
          content: 'Lica Doctor Beauty Academy Specialists'
        },
        {
          vmid: 'description',
          name: 'description',
          content: 'Certified professionals in the field will provide you with the best materials to help you achieve your goal'
        }
      ]
    }
  },
  watch:{
    lang(){
      location.reload();
    },
    'certificates.length': function() {
      this.lightBox = [];
      this.certificates.forEach(el => {
        if(window.innerWidth >= 430){
          this.lightBox.push(el.image);
        }
        else if(!this.checkPdfLink(el.image)){
          this.lightBox.push(el.image);
        }
      });
    }
  },
  computed: {
    ...mapGetters({ 
      getOne :'specialists/getOne',
      lang: 'languages/getLang'
    }),
    selectLang() {
      if(this.lang == 'ro'){
        return this.langRo;
      }
      else if(this.lang == 'ru'){
        return this.langRu;
      }
      else if(this.lang == 'es'){
        return this.langEs;
      }
      return this.langEng;
    },
    wrappWorksItems(){
      return this.specialist.works.map((item) => {
        return {
          id: item.id,
          name: item.title,
          specialization: '',
          description: item.description,
          image: item.image
        }
      })
    },
    certificates(){
      return this.specialist.certificates;
    }
  },
  methods: {
    checkPdfLink(str){
      if(str) {
        return str.substring(str.length - 3).toUpperCase() == "PDF";
      }
      else return false;
    },
    openLightBox(ind){
      if(window.innerWidth <= 430 && this.checkPdfLink(this.certificates[ind].image)){
        window.open(this.certificates[ind].image);
      }
      else {
        if(this.lightBox[ind] != undefined){
          this.indLightBox = ind;
        }
        else {
          this.indLightBox = 0;
        }
      }
    },
    async getUser(){
      try {
        let request = await fetch(`${domain}/accounts/users/${this.$route.params.id}/`);
        if(request.ok){
          let e = await request.json();
          if(e.profile){
            this.specialist = {
              ...e.profile,
              id: e.id,
              photo: e.profile.image == null ? '/img/avatar-dashboard.png' : e.profile.image,
              name: e.profile.name,
              position: e.profile.specialization,
              price: e.profile.consultation_price,
              money_type: e.profile.currency.toUpperCase(),
              about: e.profile.description,
              status: e.profile.landing_page_is_active,
              certificates: e.profile.certificates_and_diplomas,
              works: e.profile.user_page_slide,
              address: e.profile.address,
            }
          }
        }
        else {
          console.error(request);
        }
      }
      catch(ex) {
        console.error(ex);
      }
    }

  },
  async beforeMount(){
    let user = this.getOne(this.$route.params.id);

    if(user == undefined) {
      await this.getUser();
     
      if(this.specialist == undefined){
        this.e404 = true;
        this.$router.push({name: 'Home', params:{ 'lang': this.lang} })
      }
    }
    else {
      this.specialist = user;
    }
  },
  async mounted () {
    if (this.$route.hash === '#form') {
      this.modal = true;
    }
  }

}
</script>

<style lang="scss" scoped>
  .img__cont {
    border-radius: 12px !important;
  }
  .page__breadcrumbs_about {
    max-width: 706px;
  }
  .specialist_page {
    .diplomas__cnt {
      .card {
        cursor: pointer;
        background-image: url("/img/diploma3.jpg");
        background-size: cover;
        background-repeat: no-repeat;
        img {
          object-fit: cover;
          object-position: center;
          height: 100%;
        }
      }
    }
  }
  .diplomas__row {
    justify-content: center;
    flex-wrap: wrap; 
    .card {
      @media(min-width: 430px){
        margin: 40px;
      }
    }
  }

  .right_btn {
   margin-left: 15px;
   @media(max-width: 430px){
     margin-left: 0;
     margin-top: 15px;
   }
  }
  
  .address {
    color: #6B6B78;
    margin-top:10px;
  }
  .pdf_object {
    pointer-events: none;
  }
  .specialist_page {
    ::v-deep .page__breadcrumbs_about {
      p {
        margin: 16px 0;
      }
      strong {
        font-weight: 900 !important;
      }
    }
  }
  a {
    cursor: pointer;
  }
</style>